import axios from "axios";
import 'react-datepicker/dist/react-datepicker.css';

import React, { useState, useEffect } from "react";
import { env } from './const';
import { useLoading, Bars } from '@agney/react-loading';
import { headers } from '../utils/common';
//import moment from "moment";
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import DataTable from "react-data-table-component";
import { CSVLink } from "react-csv";
import DatePicker from 'react-datepicker';
import { FaCalendarAlt } from 'react-icons/fa';
import Loader from "./Loader";
import { Modal, Button } from 'react-bootstrap';
import moment from 'moment-timezone';



// const ReadingByDate = () => {
//     const { containerProps, indicatorEl } = useLoading({
//         loading: true,
//         indicator: <Bars width="30" color="#333" />
//     });
//     const [headersobj] = useState(headers());
//     const [showLoader, setShowLoader] = useState(false);
//     const [farmerList, setFarmerList] = useState([]);
//     const [farmsList, setFarmsList] = useState([]);
//     const [devicesList, setDevicesList] = useState([]);
//     const [selectedFarmer, setSelectedFarmer] = useState('');
//     const [showTabs, setShowTabs] = useState(false);
//     const [deviceID, setDeviceID] = useState('');
//     const [uuid, setuuid] = useState('')
//     const [showReadings, setShowReadings] = useState(false);
//     const [value, onChange] = useState(new Date());
//     const [deviceReadings, setDeviceReadings] = useState([]);
//     const [progress, setProgress] = useState(true);
//     const [selectedBarn, setSelectedBarn] = useState('');
//   const [selectedBatch, setSelectedBatch] = useState('');
//   const [startDate, setStartDate] = useState(null);
//   const [endDate, setEndDate] = useState(null);
//   const [barns, setBarns] = useState([]);
//   const [batches, setBatches] = useState([]);
//   const [readings, setReadings] = useState([]);
//   const [loading, setLoading] = useState(false);
//   const [error, setError] = useState('');

//     useEffect(() => {
//         fetchBarns();
//       }, []);

//       // Fetch barns function
//       const fetchBarns = async () => {
//         try {
//           const response = await axios.get(`${env.produrl}/misc/farms`);
//           setBarns(response.data.data);
//         } catch (err) {
//           setError('Failed to fetch barns');
//         }
//       };

//       // Fetch batches when a barn is selected
//       const fetchBatches = async (barnID) => {
//         try {
//           const response = await axios.get(`${env.produrl}/misc/batches`, {
//             params: { barnID },
//           });
//           setBatches(response.data.data);
//         } catch (err) {
//           setError('Failed to fetch batches');
//         }
//       };

//       // Fetch readings based on the selected batch and date range
//       const fetchReadings = async (batchID) => {
//         if (!selectedBatch || !startDate) {
//           alert('Please select all fields');
//           return;
//         }
//         const effectiveEndDate = endDate || moment(Date()).format('YYYY-MM-DD'); // Use start date if end date is not provided
//         setLoading(true);
//         setError('');
//         try {
//           const response = await axios.get(`${env.produrl}/misc/readings`, {
//             params: {
//               batchID,
//               startDate: moment(startDate).format('YYYY-MM-DD'),
//               endDate: moment(effectiveEndDate).format('YYYY-MM-DD'),
//             },
//           });
//           setReadings(response.data.data);
//           setShowReadings(true);
//         } catch (err) {
//           setError('Failed to fetch readings');
//         } finally {
//           setLoading(false);
//         }
//       };

//       // Automatically fetch readings if all fields are filled
//       useEffect(() => {
//         if (selectedBarn && selectedBatch && startDate) {
//           fetchReadings(selectedBatch);
//         }
//       }, [selectedBarn, selectedBatch, startDate, endDate]);

//       const columns = [
//         { name: "Date", selector: "readingDate", sortable: true, width: '120px', cell: row => <div>{moment(row.readingDate).format('YYYY-MM-DD')}</div> },
//         { name: "Avg Temperature", selector: "avgTemperature", sortable: true, width: '150px', cell: row => <div>{row.avgTemperature.toFixed(4)}</div> },
//         { name: "Avg Ammonia", selector: "avgAmmonia", width: '150px', sortable: true, cell: row => <div>{row.avgAmmonia.toFixed(4)}</div> },
//         { name: "Avg Heat Index", selector: "avgHeatIndex", width: '150px', sortable: true, cell: row => <div>{row.avgHeatIndex.toFixed(4)}</div> },
//         { name: "Avg Relative Humidity", selector: "avgRealtiveHumidity", sortable: true, width: '150px', cell: row => <div>{row.avgRealtiveHumidity.toFixed(4)}</div> },
//         { name: "Avg Light", selector: "avgLight", width: '150px', sortable: true, cell: row => <div>{row.avgLight.toFixed(4)}</div> },
//         // { name: "Avg Light Exposure (Minutes)", selector: "avgLightExposedMinutesPerDay", width: '150px', sortable: true, cell: row => <div>{row.avgLightExposedMinutesPerDay.toFixed(4)}</div> },
//         { name: "Avg CO2", selector: "avgCO2", sortable: true, cell: row => <div>{row.avgCO2.toFixed(4)}</div> }
//       ];



//     return (
//         <div className="BatchOperations">
//             <div className="row">
//                 <div className="col-md-12">
//                     <h3>Readings</h3>
//                     <div className="card">
//                         <div className="card-body">
//                             <div className="row">
//                                 <div className="col-md-3">
//                                     <label>Select Barn</label>
//                                     <select
//                                         className="form-control"
//                                         value={selectedBarn}
//                                         onChange={(e) => {
//                                             setSelectedBarn(e.target.value);
//                                             fetchBatches(e.target.value);
//                                         }}
//                                     >
//                                         <option value="">-Select Barn-</option>
//                                         {barns.map((barn) => (
//                                             <option key={barn.barnID} value={barn.barnID}>
//                                                 {barn.barnName}
//                                             </option>
//                                         ))}
//                                     </select>
//                                 </div>
//                                 <div className="col-md-3">
//                                     <label>Select Batch</label>
//                                     <select
//                                         className="form-control"
//                                         value={selectedBatch}
//                                         onChange={(e) => setSelectedBatch(e.target.value)}
//                                     >
//                                         <option value="">-Select Batch-</option>
//                                         {batches.map((batch) => (
//                                             <option key={batch.id} value={batch.id.toString()}>
//                                                 {batch.batchName}
//                                             </option>
//                                         ))}
//                                     </select>
//                                 </div>
//                                 <div className="col-md-3 ">
//                                     <label>Start Date</label>
//                                     <DatePicker
//                                         selected={startDate}
//                                         onChange={(date) => setStartDate(date)}
//                                         customInput={
//                                             <div className="input-group">
//                                                 <input
//                                                     type="text"
//                                                     className="form-control"
//                                                     placeholder="Start Date"
//                                                     value={startDate ? moment(startDate).format('YYYY-MM-DD') : ''}
//                                                     readOnly
//                                                 />
//                                                 <span className="input-group-text">
//                                                     <FaCalendarAlt />
//                                                 </span>
//                                             </div>
//                                         }
//                                     />
//                                 </div>
//                                 <div className="col-md-3 ">
//                                     <label>End Date</label>
//                                     <DatePicker
//                                         selected={endDate}
//                                         onChange={(date) => setEndDate(date)}
//                                         customInput={
//                                             <div className="input-group">
//                                                 <input
//                                                     type="text"
//                                                     className="form-control"
//                                                     placeholder="End Date"
//                                                     value={endDate ? moment(endDate).format('YYYY-MM-DD') : ''}
//                                                     readOnly
//                                                 />
//                                                 <span className="input-group-text">
//                                                     <FaCalendarAlt />
//                                                 </span>
//                                             </div>
//                                         }
//                                     />
//                                 </div>
//                                 <div className="col-md-2">
//                                     <label>&nbsp;</label>
//                                     <div className="dashboardLoader" style={{ marginTop: 10 }}>
//                                         {showLoader ? (
//                                             <section {...containerProps} style={{ marginTop: "30px" }}>
//                                                 {indicatorEl}
//                                             </section>
//                                         ) : null}
//                                     </div>
//                                 </div>
//                             </div>
//                             <div className="DeviceReadings">
//                                 <div className="row">
//                                 {loading && <Loader />}
//                                     <div className="col-md-8">
//                                         <h5>Device Readings (Averages per Day)</h5>
//                                         {showReadings ? (
//                                             <DataTable
//                                                 // progressPending={progress}
//                                                 progressPending={false}
//                                                 columns={columns}
//                                                 data={readings}
//                                                 defaultSortField="createdDate"
//                                                 defaultSortAsc={false}
//                                                 pagination
//                                                 dense={false}
//                                                 highlightOnHover
//                                                 striped
//                                                 paginationPerPage={15}
//                                                 paginationRowsPerPageOptions={[10, 25, 50, 100]}
//                                             />
//                                         ) : (
//                                             <p>No device readings available for the selected date.</p>
//                                         )}
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </div>
//     );
// };


// const ReadingByDate = () => {
//     const { containerProps, indicatorEl } = useLoading({
//         loading: true,
//         indicator: <Bars width="30" color="#333" />
//     });

//     const [barns, setBarns] = useState([]);
//     const [batches, setBatches] = useState([]);
//     const [selectedBarn, setSelectedBarn] = useState('');
//     const [selectedBatch, setSelectedBatch] = useState('');
//     const [dateRange, setDateRange] = useState([null, null]);
//     const [readings, setReadings] = useState([]);
//     const [loading, setLoading] = useState(false);
//     const [error, setError] = useState('');
//     const [showReadings, setShowReadings] = useState(false);
//     const [showLoader, setShowLoader] = useState(false);

//     const [startDate, endDate] = dateRange;

//     useEffect(() => {
//         fetchBarns();
//     }, []);

//     // Fetch barns function
//     const fetchBarns = async () => {
//         try {
//             const response = await axios.get(`${env.produrl}/misc/farms`);
//             setBarns(response.data.data);
//         } catch (err) {
//             setError('Failed to fetch barns');
//         }
//     };

//     // Fetch batches when a barn is selected
//     const fetchBatches = async (barnID) => {
//         try {
//             const response = await axios.get(`${env.produrl}/misc/batches`, {
//                 params: { barnID },
//             });
//             setBatches(response.data.data);
//         } catch (err) {
//             setError('Failed to fetch batches');
//         }
//     };

//     // Fetch readings based on the selected batch and date range
//     const fetchReadings = async () => {
//         if (!selectedBatch || !startDate) {
//             alert('Please select all fields');
//             return;
//         }
//         const effectiveEndDate = endDate || moment(Date()).format('YYYY-MM-DD'); // Use start date if end date is not provided
//         setLoading(true);
//         setError('');
//         try {
//             const response = await axios.get(`${env.produrl}/misc/readings`, {
//                 params: {
//                     batchID: selectedBatch,
//                     startDate: moment(startDate).format('YYYY-MM-DD'),
//                     endDate: moment(effectiveEndDate).format('YYYY-MM-DD'),
//                 },
//             });
//             setReadings(response.data.data);
//             setShowReadings(true);
//         } catch (err) {
//             setError('Failed to fetch readings');
//         } finally {
//             setLoading(false);
//         }
//     };

//     // Automatically fetch readings if all fields are filled
//     useEffect(() => {
//         if (selectedBarn && selectedBatch && startDate) {
//             fetchReadings();
//         }
//     }, [selectedBarn, selectedBatch, startDate, endDate]);

//     const columns = [
//         { name: "Date", selector: "readingDate", sortable: true, width: '120px', cell: row => <div>{moment(row.readingDate).format('YYYY-MM-DD')}</div> },
//         { name: "Avg Temperature", selector: "avgTemperature", sortable: true, width: '180px', cell: row => <div>{row.avgTemperature.toFixed(4)}</div> },
//         { name: "Avg Ammonia", selector: "avgAmmonia", width: '150px', sortable: true, cell: row => <div>{row.avgAmmonia.toFixed(4)}</div> },
//         { name: "Avg Heat Index", selector: "avgHeatIndex", width: '150px', sortable: true, cell: row => <div>{row.avgHeatIndex.toFixed(4)}</div> },
//         { name: "Avg Relative Humidity", selector: "avgRealtiveHumidity", sortable: true, width: '200px', cell: row => <div>{row.avgRealtiveHumidity.toFixed(4)}</div> },
//         { name: "Avg Light", selector: "avgLight", width: '150px', sortable: true, cell: row => <div>{row.avgLight.toFixed(4)}</div> },
//         { name: "Avg CO2", selector: "avgCO2", sortable: true, cell: row => <div>{row.avgCO2.toFixed(4)}</div> }
//     ];

//     return (
//         <div className="BatchOperations">
//             <div className="row">
//                 <div className="col-md-12">
//                     <h3>Readings</h3>
//                     <div className="card">
//                         <div className="card-body">
//                             <div className="row">
//                                 <div className="col-md-3">
//                                     <label>Select Barn</label>
//                                     <select
//                                         className="form-control"
//                                         value={selectedBarn}
//                                         onChange={(e) => {
//                                             setSelectedBarn(e.target.value);
//                                             fetchBatches(e.target.value);
//                                         }}
//                                     >
//                                         <option value="">-Select Barn-</option>
//                                         {barns.map((barn) => (
//                                             <option key={barn.barnID} value={barn.barnID}>
//                                                 {barn.barnName}
//                                             </option>
//                                         ))}
//                                     </select>
//                                 </div>
//                                 <div className="col-md-3">
//                                     <label>Select Batch</label>
//                                     <select
//                                         className="form-control"
//                                         value={selectedBatch}
//                                         onChange={(e) => setSelectedBatch(e.target.value)}
//                                     >
//                                         <option value="">-Select Batch-</option>
//                                         {batches.map((batch) => (
//                                             <option key={batch.id} value={batch.id.toString()}>
//                                                 {batch.batchName}
//                                             </option>
//                                         ))}
//                                     </select>
//                                 </div>
//                                 <div className="col-md-3">
//                                     <label>Select Date Range</label>
//                                     <DatePicker
//                                         selectsRange
//                                         startDate={startDate}
//                                         endDate={endDate}
//                                         onChange={(update) => setDateRange(update)}
//                                         customInput={
//                                             <div className="input-group">
//                                                 <input
//                                                     type="text"
//                                                     className="form-control"
//                                                     placeholder="Select Date Range"
//                                                     value={
//                                                         startDate
//                                                             ? `${moment(startDate).format('YYYY-MM-DD')} - ${endDate ? moment(endDate).format('YYYY-MM-DD') : '...'}`
//                                                             : ''
//                                                     }
//                                                     readOnly
//                                                 />
//                                                 <span className="input-group-text">
//                                                     <FaCalendarAlt />
//                                                 </span>
//                                             </div>
//                                         }
//                                         withPortal
//                                     />
//                                 </div>
//                                 <div className="col-md-2">
//                                     <label>&nbsp;</label>
//                                     <div className="dashboardLoader" style={{ marginTop: 10 }}>
//                                         {showLoader ? (
//                                             <section {...containerProps} style={{ marginTop: "30px" }}>
//                                                 {indicatorEl}
//                                             </section>
//                                         ) : null}
//                                     </div>
//                                 </div>
//                             </div>
//                             <div className="DeviceReadings">
//                                 <div className="row">
//                                     {loading && <Loader />}
//                                     <div className="col-md-">
//                                         <h5>Device Readings (Averages per Day)</h5>
//                                         {showReadings ? (
//                                             <DataTable
//                                                 progressPending={loading}
//                                                 columns={columns}
//                                                 data={readings}
//                                                 defaultSortField="createdDate"
//                                                 defaultSortAsc={false}
//                                                 pagination
//                                                 dense={false}
//                                                 highlightOnHover
//                                                 striped
//                                                 paginationPerPage={15}
//                                                 paginationRowsPerPageOptions={[10, 25, 50, 100]}
//                                             />
//                                         ) : (
//                                             <p>No device readings available for the selected date.</p>
//                                         )}
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </div>
//     );
// };

// const ReadingByDate = () => {
//     const { containerProps, indicatorEl } = useLoading({
//         loading: true,
//         indicator: <Bars width="30" color="#333" />
//     });

//     const [barns, setBarns] = useState([]);
//     const [batches, setBatches] = useState([]);
//     const [selectedBarn, setSelectedBarn] = useState('');
//     const [selectedBatch, setSelectedBatch] = useState('');
//     const [dateRange, setDateRange] = useState([null, null]);
//     const [readings, setReadings] = useState([]);
//     const [loading, setLoading] = useState(false);
//     const [error, setError] = useState('');
//     const [showReadings, setShowReadings] = useState(false);
//     const [showLoader, setShowLoader] = useState(false);

//     const [startDate, endDate] = dateRange;

//     // Modal state
//     const [showModal, setShowModal] = useState(false);
//     const [selectedReading, setSelectedReading] = useState(null);

//     useEffect(() => {
//         fetchBarns();
//     }, []);

//     const fetchBarns = async () => {
//         try {
//             const response = await axios.get(`${env.produrl}/misc/farms`);
//             setBarns(response.data.data);
//         } catch (err) {
//             setError('Failed to fetch barns');
//         }
//     };

//     const fetchBatches = async (barnID) => {
//         try {
//             const response = await axios.get(`${env.produrl}/misc/batches`, {
//                 params: { barnID },
//             });
//             setBatches(response.data.data);
//         } catch (err) {
//             setError('Failed to fetch batches');
//         }
//     };

//     const fetchReadings = async () => {
//         if (!selectedBatch || !startDate) {
//             alert('Please select all fields');
//             return;
//         }
//         const effectiveEndDate = endDate || moment(Date()).format('YYYY-MM-DD');
//         setLoading(true);
//         setError('');
//         try {
//             const response = await axios.get(`${env.produrl}/misc/readings`, {
//                 params: {
//                     batchID: selectedBatch,
//                     startDate: moment(startDate).format('YYYY-MM-DD'),
//                     endDate: moment(effectiveEndDate).format('YYYY-MM-DD'),
//                 },
//             });
//             setReadings(response.data.data);
//             setShowReadings(true);
//         } catch (err) {
//             setError('Failed to fetch readings');
//         } finally {
//             setLoading(false);
//         }
//     };

//     useEffect(() => {
//         if (selectedBarn && selectedBatch && startDate) {
//             fetchReadings();
//         }
//     }, [selectedBarn, selectedBatch, startDate, endDate]);

//     const handleShowModal = (reading) => {
//         setSelectedReading(reading);
//         setShowModal(true);
//     };

//     const handleCloseModal = () => {
//         setShowModal(false);
//         setSelectedReading(null);
//     };
//     const handleDateChange = async (date, uuid) => {
//         const selectedDate = moment(date).format('YYYY-MM-DD');
//         setLoading(true);
//         try {
//             const response = await axios.get(`${env.produrl}/misc/deviceReadings/${uuid}/${selectedDate}`);
//             setSelectedReading(response.data.data || []); // Ensure it's an array
//             setShowModal(true);
//         } catch (err) {
//             alert('Error in fetching data');
//         } finally {
//             setLoading(false);
//         }
//     };

//     const columns = [
//         { name: "Date", selector: "readingDate", sortable: true, width: '120px', cell: row => <div>{moment(row.readingDate).format('YYYY-MM-DD')}</div> },
//         { name: "Avg Temperature", selector: "avgTemperature", sortable: true, width: '180px', cell: row => <div>{row.avgTemperature.toFixed(4)}</div> },
//         { name: "Avg Ammonia", selector: "avgAmmonia", width: '150px', sortable: true, cell: row => <div>{row.avgAmmonia.toFixed(4)}</div> },
//         { name: "Avg Heat Index", selector: "avgHeatIndex", width: '150px', sortable: true, cell: row => <div>{row.avgHeatIndex.toFixed(4)}</div> },
//         { name: "Avg Relative Humidity", selector: "avgRealtiveHumidity", sortable: true, width: '200px', cell: row => <div>{row.avgRealtiveHumidity.toFixed(4)}</div> },
//         { name: "Avg Light", selector: "avgLight", width: '150px', sortable: true, cell: row => <div>{row.avgLight.toFixed(4)}</div> },
//         { name: "Avg CO2", selector: "avgCO2", sortable: true, cell: row => <div>{row.avgCO2.toFixed(4)}</div> },
//         {
//             name: "Date",
//             selector: "readingDate",
//             sortable: true,
//             width: '120px',
//             cell: row => (
//                 <a
//                     href="SeeDay readings"
//                     onClick={(e) => {
//                         e.preventDefault();
//                         handleDateChange(row.readingDate, row.uuid); // Assuming `uuid` is part of the row data
//                     }}
//                 >
//                     {moment(row.readingDate).format('YYYY-MM-DD')}
//                 </a>
//             )
//         },
//     ];

//     const modalColumns = [
//         { name: "Date Time", selector: "createdDate", sortable: true, width: '150px', cell: row => <div>{moment(row.createdDate).format('HH:mm A')}</div> },
//         { name: "Temperature", selector: "temp", sortable: true, width: '150px', cell: row => <div>{row.temp}</div> },
//         { name: "Ammonia", selector: "nh", sortable: true, width: '150px', cell: row => <div>{row.nh}</div> },
//         { name: "Heat Index", selector: "heatIndex", sortable: true, width: '150px', cell: row => <div>{row.heatIndex}</div> },
//         { name: "Relative Humidity", selector: "rh", sortable: true, width: '150px', cell: row => <div>{row.rh}</div> },
//         { name: "Light", selector: "light", sortable: true, width: '150px', cell: row => <div>{row.light}</div> },
//         { name: "CO2", selector: "co", sortable: true, width: '150px', cell: row => <div>{row.co}</div> },
//     ];


//     return (
//         <div className="BatchOperations">
//             <div className="row">
//                 <div className="col-md-12">
//                     <h3>Readings</h3>
//                     <div className="card">
//                         <div className="card-body">
//                             <div className="row">
//                                 <div className="col-md-3">
//                                     <label>Select Barn</label>
//                                     <select
//                                         className="form-control"
//                                         value={selectedBarn}
//                                         onChange={(e) => {
//                                             setSelectedBarn(e.target.value);
//                                             fetchBatches(e.target.value);
//                                         }}
//                                     >
//                                         <option value="">-Select Barn-</option>
//                                         {barns.map((barn) => (
//                                             <option key={barn.barnID} value={barn.barnID}>
//                                                 {barn.barnName}
//                                             </option>
//                                         ))}
//                                     </select>
//                                 </div>
//                                 <div className="col-md-3">
//                                     <label>Select Batch</label>
//                                     <select
//                                         className="form-control"
//                                         value={selectedBatch}
//                                         onChange={(e) => setSelectedBatch(e.target.value)}
//                                     >
//                                         <option value="">-Select Batch-</option>
//                                         {batches.map((batch) => (
//                                             <option key={batch.id} value={batch.id.toString()}>
//                                                 {batch.batchName}
//                                             </option>
//                                         ))}
//                                     </select>
//                                 </div>
//                                 <div className="col-md-3">
//                                     <label>Select Date Range</label>
//                                     <DatePicker
//                                         selectsRange
//                                         startDate={startDate}
//                                         endDate={endDate}
//                                         onChange={(update) => setDateRange(update)}
//                                         withPortal
//                                         className="form-control"
//                                     />
//                                 </div>
//                             </div>
//                             <div className="DeviceReadings mt-4">
//                                 <div className="row">
//                                     {loading && <Loader />}
//                                     <div className="col-md-10">
//                                         <h5>Device Readings (Averages per Day)</h5>
//                                         {showReadings ? (
//                                             <DataTable
//                                                 progressPending={loading}
//                                                 columns={columns}
//                                                 data={readings}
//                                                 defaultSortField="createdDate"
//                                                 defaultSortAsc={false}
//                                                 pagination
//                                                 dense={false}
//                                                 highlightOnHover
//                                                 striped
//                                                 paginationPerPage={15}
//                                                 paginationRowsPerPageOptions={[10, 25, 50, 100]}
//                                             />
//                                         ) : (
//                                             <p>No device readings available for the selected date.</p>
//                                         )}
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </div>

//             {/* Modal to show detailed reading */}
//             <Modal show={showModal} onHide={handleCloseModal} size="lg">
//                 <Modal.Header closeButton>
//                     <Modal.Title>Reading Details for {moment(selectedReading?.[0]?.createdDate).format('YYYY-MM-DD')}</Modal.Title>
//                 </Modal.Header>
//                 <Modal.Body>
//                     {selectedReading.length > 0 ? (
//                         <DataTable
//                             columns={modalColumns}
//                             data={selectedReading}
//                             defaultSortField="createdDate"
//                             defaultSortAsc={false}
//                             pagination
//                             dense={false}
//                             highlightOnHover
//                             striped
//                             paginationPerPage={10}
//                             paginationRowsPerPageOptions={[5, 10, 20]}
//                         />
//                     ) : (
//                         <p>No reading details available.</p>
//                     )}
//                 </Modal.Body>
//                 <Modal.Footer>
//                     <Button variant="secondary" onClick={handleCloseModal}>
//                         Close
//                     </Button>
//                 </Modal.Footer>
//             </Modal>
//         </div>
//     );
// };

const ReadingByDate = () => {
    const { containerProps, indicatorEl } = useLoading({
        loading: true,
        indicator: <Bars width="30" color="#333" />
    });

    const [barns, setBarns] = useState([]);
    const [batches, setBatches] = useState([]);
    const [selectedBarn, setSelectedBarn] = useState('');
    const [selectedBatch, setSelectedBatch] = useState('');
    const [dateRange, setDateRange] = useState([null, null]);
    const [readings, setReadings] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [showReadings, setShowReadings] = useState(false);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    //   const [startDate, endDate] = dateRange;

    // Modal state
    const [showModal, setShowModal] = useState(false);
    const [selectedReading, setSelectedReading] = useState([]); // Initialize as an empty array

    useEffect(() => {
        fetchBarns();
    }, []);

    const fetchBarns = async () => {
        try {
            const response = await axios.get(`${env.produrl}/misc/farms`);
            setBarns(response.data.data);
        } catch (err) {
            setError('Failed to fetch barns');
        }
    };

    const fetchBatches = async (barnID) => {
        try {
            const response = await axios.get(`${env.produrl}/misc/batches`, {
                params: { barnID },
            });
            setBatches(response.data.data);
        } catch (err) {
            setError('Failed to fetch batches');
        }
    };

    const fetchReadings = async () => {
        if (!selectedBatch || !startDate || !endDate) {
            alert('Please select all fields');
            return;
        }
        // const effectiveEndDate = endDate || moment(Date()).format('YYYY-MM-DD');
        // Ensure start date starts from midnight (00:00:00)
        const formattedStartDate = moment(startDate).startOf('day').format('YYYY-MM-DD HH:mm:ss');

        // Ensure end date ends at the last second of the day (23:59:59)
        const formattedEndDate = moment(endDate).endOf('day').format('YYYY-MM-DD HH:mm:ss');

        setLoading(true);
        setError('');
        try {
            const response = await axios.get(`${env.produrl}/misc/readings`, {
                params: {
                    batchID: selectedBatch,
                    startDate: formattedStartDate,
                    // endDate: moment(effectiveEndDate).format('YYYY-MM-DD'),
                    endDate: formattedEndDate,
                },
            });
            setReadings(response.data.data);
            setShowReadings(true);
        } catch (err) {
            setError('Failed to fetch readings');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (selectedBarn && selectedBatch && startDate && endDate) {
            // if(!endDate){
            //     setTimeout(()=>{fetchReadings()},3000);
            // }
            // else{
            //     fetchReadings();
            // }
            fetchReadings();
        }
    }, [selectedBarn, selectedBatch, startDate, endDate]);

    const handleDateChange = async (date, uuid) => {
        const selectedDate = moment(date).startOf('day').format('YYYY-MM-DD HH:mm:ss');
        console.log(`the date for individualy readings is : ${selectedDate} and uuid is ${uuid}`);

        setLoading(true);
        try {
            const response = await axios.get(`${env.produrl}/misc/deviceReadings/${uuid}/${selectedDate}`);
            setSelectedReading(response.data.data || []); // Ensure it's an array
            setShowModal(true);
        } catch (err) {
            alert('Error in fetching data');
        } finally {
            setLoading(false);
        }
    };

    const formatValue = (value) => {
        if (value === undefined || value === null) return 'N/A';
        return value === 0 ? 0 : value.toFixed(2);  // Display 0 if value is exactly 0
    };
    
    const formatAmmoniaValue = (value) => {
        if (value === undefined || value === null) return 'N/A';
        return value === 0 ? 0 : value.toFixed(3);  // Adjust for ammonia with 3 decimals
    };
    
    const handleCloseModal = () => {
        setShowModal(false);
        setSelectedReading([]);
    };

    // const columns = [
    //     {
    //         name: "Date",
    //         selector: "readingDate",
    //         sortable: false,
    //         width: '120px', headerStyle: {
    //             textAlign: 'center'  // Centers the header text
    //         },
    //         cell: row => (
    //             <a
    //                 href="#!"
    //                 onClick={(e) => {
    //                     e.preventDefault();
    //                     // handleDateChange(row.readingDate, row.uuid); // Assuming `uuid` is part of the row data
    //                     handleDateChange(moment(row.readingDate).tz('Asia/Kolkata').startOf('day').toDate(), row.uuid); // Using start of day to avoid time issues
    //                 }}
    //             >
    //                 {moment(row.readingDate).format('YYYY-MM-DD')}
    //             </a>
    //         ),
    //         sortFunction: (a, b) => new Date(a.createdDate) - new Date(b.createdDate)
    //     },
    //     {
    //         name: (
    //             <div style={{ textAlign: 'center',width:'190px' }}>
    //                 <div>Temperature</div>
    //                 <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '5px' }}>
    //                     <span style={{ fontSize: 'smaller', color: '#36454F' }}>Avg</span>
    //                     <span style={{ fontSize: 'smaller', color: '#36454F' }}>Min</span>
    //                     <span style={{ fontSize: 'smaller', color: '#36454F' }}>Max</span>
    //                 </div>
    //             </div>
    //         ),
    //         selector: "avgTemperature",
    //         width: '250px',
    //         headerStyle: {
    //             textAlign: 'center' , // Centers the header text
    //             // borderRight: '1px solid #000'
    //         },
    //         sortable: true,
    //         cell: row => (
    //             // <div style={{display:'flex', flexDirection:'row',gap:'5px'}}>
    //             //     <div style={{}}>Avg: {row.avgTemperature.toFixed(2)} </div>
    //             //     <div> Min: {row.minTemperature.toFixed(2)} </div>
    //             //     <div>Max: {row.maxTemperature.toFixed(2)}</div>
    //             // </div>
    //             <div style={{ display: 'flex', flexDirection: 'row', gap: '45px' }}>
    //                 <div style={{
    //                     // color: '#4caf50',
    //                      fontWeight: 'bold' 
    //                      }}>
    //                     {/* Avg:  */}
    //                     {row.avgTemperature.toFixed(2)}
    //                 </div>
    //                 <div style={{ 
    //                     // color: '#f44336',
    //                      fontWeight: 'bold' 
    //                      }}>
    //                     {/* Min:  */}
    //                     {row.minTemperature.toFixed(2)}
    //                 </div>
    //                 <div style={{
    //                     //  color: '#2196f3',
    //                       fontWeight: 'bold'
    //                     }}>
    //                     {/* Max:  */}
    //                     {row.maxTemperature.toFixed(2)}
    //                 </div>
    //             </div>

    //         ),
    //         sortFunction: (a, b) => a.avgTemperature - b.avgTemperature
    //     },
    //     {
    //         name: (
    //             <div style={{ textAlign: 'center',width:'190px' }}>
    //                 <div>Ammonia</div>
    //                 <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '5px' }}>
    //                     <span style={{ fontSize: 'smaller', color: '#36454F' }}>Avg</span>
    //                     <span style={{ fontSize: 'smaller', color: '#36454F' }}>Min</span>
    //                     <span style={{ fontSize: 'smaller', color: '#36454F' }}>Max</span>
    //                 </div>
    //             </div>
    //         ),
    //         selector: "avgAmmonia",
    //         width: '250px',
    //         headerStyle: {
    //             textAlign: 'center',  // Centers the header text
    //             // borderLeft: '1px solid #000'
    //         },
    //         sortable: true,
    //         cell: row => (
    //             <div style={{ display: 'flex', flexDirection: 'row', gap: '45px' }}>
    //                 <div style={{ 
    //                     // color: '#4caf50',
    //                      fontWeight: 'bold'
    //                       }}>
    //                     {/* Avg:  */}
    //                     {row.avgAmmonia !== undefined ? row.avgAmmonia.toFixed(3) : 'N/A'}
    //                 </div>
    //                 <div style={{ 
    //                     // color: '#f44336', 
    //                     fontWeight: 'bold'
    //                      }}>
    //                     {/* Min:  */}
    //                     {row.minAmmonia !== undefined ? row.minAmmonia.toFixed(3) : 'N/A'}
    //                 </div>
    //                 <div style={{ 
    //                     // color: '#2196f3',
    //                      fontWeight: 'bold' 
    //                      }}>
    //                     {/* Max:  */}
    //                     {row.maxAmmonia !== undefined ? row.maxAmmonia.toFixed(3) : 'N/A'}
    //                 </div>
    //             </div>
    //         ),
    //         sortFunction: (a, b) => (a.avgAmmonia || 0) - (b.avgAmmonia || 0)
    //     },

    //     {
    //         name: (
    //             <div style={{ textAlign: 'center',width:'190px' }}>
    //                 <div>Realative Humidity</div>
    //                 <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '5px' }}>
    //                     <span style={{ fontSize: 'smaller', color: '#36454F' }}>Avg</span>
    //                     <span style={{ fontSize: 'smaller', color: '#36454F' }}>Min</span>
    //                     <span style={{ fontSize: 'smaller', color: '#36454F' }}>Max</span>
    //                 </div>
    //             </div>
    //         ),
    //         selector: "avgRelativeHumidity",
    //         width: '250px',
    //         headerStyle: {
    //             textAlign: 'center'  // Centers the header text
    //         },
    //         sortable: true,
    //         cell: row => (
    //             <div style={{ display: 'flex', flexDirection: 'row', gap: '45px' }}>
    //                 <div style={{ 
    //                     // color: '#4caf50',
    //                      fontWeight: 'bold'
    //                       }}>

    //                     {row.avgRelativeHumidity !== undefined ? row.avgRelativeHumidity.toFixed(2) : 'N/A'}
    //                 </div>
    //                 <div style={{
    //                     //  color: '#f44336',
    //                       fontWeight: 'bold' 
    //                       }}>

    //                     {row.minRelativeHumidity !== undefined ? row.minRelativeHumidity.toFixed(2) : 'N/A'}
    //                 </div>
    //                 <div style={{ 
    //                     // color: '#2196f3', 
    //                     fontWeight: 'bold' 
    //                     }}>

    //                     {row.maxRelativeHumidity !== undefined ? row.maxRelativeHumidity.toFixed(2) : 'N/A'}
    //                 </div>
    //             </div>
    //         ),
    //         sortFunction: (a, b) => (a.avgRelativeHumidity || 0) - (b.avgRelativeHumidity || 0)
    //     },
    //     {
    //         name: (
    //             <div style={{ textAlign: 'center',width:'190px' }}>
    //                 <div>Light</div>
    //                 <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '5px' }}>
    //                     <span style={{ fontSize: 'smaller', color: '#36454F' }}>Avg</span>
    //                     <span style={{ fontSize: 'smaller', color: '#36454F' }}>Min</span>
    //                     <span style={{ fontSize: 'smaller', color: '#36454F' }}>Max</span>
    //                 </div>
    //             </div>
    //         ),
    //         selector: "avgLight",
    //         width: '250px',
    //         headerStyle: {
    //             textAlign: 'center' , // Centers the header text
    //             // borderLeft: '1px solid #000'
    //         },
    //         sortable: true,
    //         cell: row => (
    //             <div style={{ display: 'flex', flexDirection: 'row', gap: '45px' }}>
    //                 <div style={{ 
    //                     // color: '#4caf50', 
    //                     fontWeight: 'bold'
    //                      }}>
    //                    {row.avgLight !== undefined ? row.avgLight.toFixed(2) : 'N/A'}
    //                 </div>
    //                 <div style={{
    //                     //  color: '#f44336',
    //                       fontWeight: 'bold'
    //                        }}>
    //                    {row.minLight !== undefined ? row.minLight.toFixed(2) : 'N/A'}
    //                 </div>
    //                 <div style={{ 
    //                     // color: '#2196f3',
    //                      fontWeight: 'bold' 
    //                      }}>
    //                    {row.maxLight !== undefined ? row.maxLight.toFixed(2) : 'N/A'}
    //                 </div>
    //             </div>
    //         ),
    //         sortFunction: (a, b) => (a.avgLight || 0) - (b.avgLight || 0)
    //     },
    //     {
    //         name:(
    //             <div style={{ textAlign: 'center',width:'190px' }}>
    //                 <div>Heat Index</div>
    //                 <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '5px' }}>
    //                     <span style={{ fontSize: 'smaller', color: '#36454F' }}>Avg</span>
    //                     <span style={{ fontSize: 'smaller', color: '#36454F' }}>Min</span>
    //                     <span style={{ fontSize: 'smaller', color: '#36454F' }}>Max</span>
    //                 </div>
    //             </div>
    //         ),
    //         selector: "avgHeatIndex",
    //         width: '250px',
    //         headerStyle: {
    //             textAlign: 'center'  // Centers the header text
    //         },
    //         sortable: true,
    //         cell: row => (
    //             <div style={{ display: 'flex', flexDirection: 'row', gap: '45px' }}>
    //                 <div style={{ 
    //                     // color: '#4caf50',
    //                      fontWeight: 'bold'
    //                       }}>
    //                    {row.avgHeatIndex !== undefined ? row.avgHeatIndex.toFixed(2) : 'N/A'}
    //                 </div>
    //                 <div style={{
    //                     //  color: '#f44336', 
    //                      fontWeight: 'bold' 
    //                      }}>
    //                    {row.minHeatIndex !== undefined ? row.minHeatIndex.toFixed(2) : 'N/A'}
    //                 </div>
    //                 <div style={{ 
    //                     // color: '#2196f3',
    //                      fontWeight: 'bold'
    //                       }}>
    //                     {row.maxHeatIndex !== undefined ? row.maxHeatIndex.toFixed(2) : 'N/A'}
    //                 </div>
    //             </div>
    //         ),
    //         sortFunction: (a, b) => (a.avgHeatIndex || 0) - (b.avgHeatIndex || 0)
    //     },
    //     {
    //         name: (
    //             <div style={{ textAlign: 'center',width:'190px' }}>
    //                 <div>CO2</div>
    //                 <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '5px' }}>
    //                     <span style={{ fontSize: 'smaller', color: '#36454F' }}>Avg</span>
    //                     <span style={{ fontSize: 'smaller', color: '#36454F' }}>Min</span>
    //                     <span style={{ fontSize: 'smaller', color: '#36454F' }}>Max</span>
    //                 </div>
    //             </div>
    //         ),
    //         selector: "avgCO2",
    //         width: '250px',
    //         headerStyle: {
    //             textAlign: 'center'  // Centers the header text
    //         },
    //         sortable: true,
    //         cell: row => (
    //             <div style={{ display: 'flex', flexDirection: 'row', gap: '30px' }}>
    //                 <div style={{ 
    //                     // color: '#4caf50',
    //                      fontWeight: 'bold'
    //                       }}>
    //                     {row.avgCO2 !== undefined ? row.avgCO2.toFixed(2) : 'N/A'}
    //                 </div>
    //                 <div style={{
    //                     //  color: '#f44336',
    //                       fontWeight: 'bold'
    //                        }}>
    //                    {row.minCO2 !== undefined ? row.minCO2.toFixed(2) : 'N/A'}
    //                 </div>
    //                 <div style={{
    //                     //  color: '#2196f3',
    //                       fontWeight: 'bold'
    //                        }}>
    //                    {row.maxCO2 !== undefined ? row.maxCO2.toFixed(2) : 'N/A'}
    //                 </div>
    //             </div>
    //         ),
    //         sortFunction: (a, b) => (a.avgCO2 || 0) - (b.avgCO2 || 0)
    //     }
    // ];

    // const columns = [
    //     {
    //       name: "Date",
    //       selector: "readingDate",
    //       sortable: false,
    //       width: '120px',
    //       headerStyle: {
    //         textAlign: 'center', // Centers the header text
    //       },
    //       cell: row => (
    //         <a
    //           href="#!"
    //           onClick={(e) => {
    //             e.preventDefault();
    //             handleDateChange(moment(row.readingDate).tz('Asia/Kolkata').startOf('day').toDate(), row.uuid);
    //           }}
    //         >
    //           {moment(row.readingDate).format('YYYY-MM-DD')}
    //         </a>
    //       ),
    //       sortFunction: (a, b) => new Date(a.createdDate) - new Date(b.createdDate)
    //     },
    //     {
    //       name: (
    //         <div style={{ textAlign: 'center', width: '190px' }}>
    //           <div>Temperature</div>
    //           <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '5px' }}>
    //             <span style={{ fontSize: 'smaller', color: '#36454F' }}>Avg</span>
    //             <span style={{ fontSize: 'smaller', color: '#36454F' }}>Min</span>
    //             <span style={{ fontSize: 'smaller', color: '#36454F' }}>Max</span>
    //           </div>
    //         </div>
    //       ),
    //       selector: "avgTemperature",
    //       width: '250px',
    //       headerStyle: {
    //         textAlign: 'center',
    //       },
    //       sortable: true,
    //       cell: row => (
    //         <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
    //           <div style={{ flex: 1, textAlign: 'center', fontWeight: 'bold' }}>
    //             {row.avgTemperature !== undefined ? row.avgTemperature.toFixed(2) : 'N/A'}
    //           </div>
    //           <div style={{ flex: 1, textAlign: 'center', fontWeight: 'bold' }}>
    //             {row.minTemperature !== undefined ? row.minTemperature.toFixed(2) : 'N/A'}
    //           </div>
    //           <div style={{ flex: 1, textAlign: 'center', fontWeight: 'bold' }}>
    //             {row.maxTemperature !== undefined ? row.maxTemperature.toFixed(2) : 'N/A'}
    //           </div>
    //         </div>
    //       ),
    //       sortFunction: (a, b) => a.avgTemperature - b.avgTemperature
    //     },
    //     {
    //       name: (
    //         <div style={{ textAlign: 'center', width: '190px' }}>
    //           <div>Ammonia</div>
    //           <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '5px' }}>
    //             <span style={{ fontSize: 'smaller', color: '#36454F',backgroundColor:'yellow' }}>Avg</span>
    //             <span style={{ fontSize: 'smaller', color: '#36454F',backgroundColor:'orange' }}>Min</span>
    //             <span style={{ fontSize: 'smaller', color: '#36454F',backgroundColor:'grey' }}>Max</span>
    //           </div>
    //         </div>
    //       ),
    //       selector: "avgAmmonia",
    //       width: '250px',
    //       headerStyle: {
    //         textAlign: 'center',
    //       },
    //       sortable: true,
    //       cell: row => (
    //         <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
    //           <div style={{ flex: 1, textAlign: 'center', fontWeight: 'bold',backgroundColor:'red' }}>
    //             {row.avgAmmonia !== undefined ? row.avgAmmonia.toFixed(3) : 'N/A'}
    //           </div>
    //           <div style={{ flex: 1, textAlign: 'center', fontWeight: 'bold',backgroundColor:'green' }}>
    //             {row.minAmmonia !== undefined ? row.minAmmonia.toFixed(3) : 'N/A'}
    //           </div>
    //           <div style={{ flex: 1, textAlign: 'center', fontWeight: 'bold',backgroundColor:'violet' }}>
    //             {row.maxAmmonia !== undefined ? row.maxAmmonia.toFixed(3) : 'N/A'}
    //           </div>
    //         </div>
    //       ),
    //       sortFunction: (a, b) => (a.avgAmmonia || 0) - (b.avgAmmonia || 0)
    //     },
    //     {
    //       name: (
    //         <div style={{ textAlign: 'center', width: '190px' }}>
    //           <div>Relative Humidity</div>
    //           <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '5px' }}>
    //           <span style={{ fontSize: 'smaller', color: '#36454F',backgroundColor:'yellow' }}>Avg</span>
    //             <span style={{ fontSize: 'smaller', color: '#36454F',backgroundColor:'orange' }}>Min</span>
    //             <span style={{ fontSize: 'smaller', color: '#36454F',backgroundColor:'grey' }}>Max</span>
    //           </div>
    //         </div>
    //       ),
    //       selector: "avgRelativeHumidity",
    //       width: '250px',
    //       headerStyle: {
    //         textAlign: 'center',
    //       },
    //       sortable: true,
    //       cell: row => (
    //         <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
    //           <div style={{ flex: 1, textAlign: 'center', fontWeight: 'bold',backgroundColor:'red' }}>
    //             {row.avgRelativeHumidity !== undefined ? row.avgRelativeHumidity.toFixed(2) : 'N/A'}
    //           </div>
    //           <div style={{ flex: 1, textAlign: 'center', fontWeight: 'bold',backgroundColor:'green' }}>
    //             {row.minRelativeHumidity !== undefined ? row.minRelativeHumidity.toFixed(2) : 'N/A'}
    //           </div>
    //           <div style={{ flex: 1, textAlign: 'center', fontWeight: 'bold',backgroundColor:'violet' }}>
    //             {row.maxRelativeHumidity !== undefined ? row.maxRelativeHumidity.toFixed(2) : 'N/A'}
    //           </div>
    //         </div>
    //       ),
    //       sortFunction: (a, b) => (a.avgRelativeHumidity || 0) - (b.avgRelativeHumidity || 0)
    //     },
    //     {
    //       name: (
    //         <div style={{ textAlign: 'center', width: '190px' }}>
    //           <div>Light</div>
    //           <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '5px' }}>
    //             <span style={{ fontSize: 'smaller', color: '#36454F' }}>Avg</span>
    //             <span style={{ fontSize: 'smaller', color: '#36454F' }}>Min</span>
    //             <span style={{ fontSize: 'smaller', color: '#36454F' }}>Max</span>
    //           </div>
    //         </div>
    //       ),
    //       selector: "avgLight",
    //       width: '250px',
    //       headerStyle: {
    //         textAlign: 'center',
    //       },
    //       sortable: true,
    //       cell: row => (
    //         <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
    //           <div style={{ flex: 1, textAlign: 'center', fontWeight: 'bold' }}>
    //             {row.avgLight !== undefined ? row.avgLight.toFixed(2) : 'N/A'}
    //           </div>
    //           <div style={{ flex: 1, textAlign: 'center', fontWeight: 'bold' }}>
    //             {row.minLight !== undefined ? row.minLight.toFixed(2) : 'N/A'}
    //           </div>
    //           <div style={{ flex: 1, textAlign: 'center', fontWeight: 'bold' }}>
    //             {row.maxLight !== undefined ? row.maxLight.toFixed(2) : 'N/A'}
    //           </div>
    //         </div>
    //       ),
    //       sortFunction: (a, b) => (a.avgLight || 0) - (b.avgLight || 0)
    //     },
    //     {
    //       name: (
    //         <div style={{ textAlign: 'center', width: '190px' }}>
    //           <div>Heat Index</div>
    //           <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '5px' }}>
    //             <span style={{ fontSize: 'smaller', color: '#36454F' }}>Avg</span>
    //             <span style={{ fontSize: 'smaller', color: '#36454F' }}>Min</span>
    //             <span style={{ fontSize: 'smaller', color: '#36454F' }}>Max</span>
    //           </div>
    //         </div>
    //       ),
    //       selector: "avgHeatIndex",
    //       width: '250px',
    //       headerStyle: {
    //         textAlign: 'center',
    //       },
    //       sortable: true,
    //       cell: row => (
    //         <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
    //           <div style={{ flex: 1, textAlign: 'center', fontWeight: 'bold' }}>
    //             {row.avgHeatIndex !== undefined ? row.avgHeatIndex.toFixed(2) : 'N/A'}
    //           </div>
    //           <div style={{ flex: 1, textAlign: 'center', fontWeight: 'bold' }}>
    //             {row.minHeatIndex !== undefined ? row.minHeatIndex.toFixed(2) : 'N/A'}
    //           </div>
    //           <div style={{ flex: 1, textAlign: 'center', fontWeight: 'bold' }}>
    //             {row.maxHeatIndex !== undefined ? row.maxHeatIndex.toFixed(2) : 'N/A'}
    //           </div>
    //         </div>
    //       ),
    //       sortFunction: (a, b) => (a.avgHeatIndex || 0) - (b.avgHeatIndex || 0)
    //     },
    //     {
    //       name: (
    //         <div style={{ textAlign: 'center', width: '190px' }}>
    //           <div>CO2</div>
    //           <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '5px' }}>
    //             <span style={{ fontSize: 'smaller', color: '#36454F' }}>Avg</span>
    //             <span style={{ fontSize: 'smaller', color: '#36454F' }}>Min</span>
    //             <span style={{ fontSize: 'smaller', color: '#36454F' }}>Max</span>
    //           </div>
    //         </div>
    //       ),
    //       selector: "avgCO2",
    //       width: '250px',
    //       headerStyle: {
    //         textAlign: 'center',
    //       },
    //       sortable: true,
    //       cell: row => (
    //         <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
    //           <div style={{ flex: 1, textAlign: 'center', fontWeight: 'bold' }}>
    //             {row.avgCO2 !== undefined ? row.avgCO2.toFixed(2) : 'N/A'}
    //           </div>
    //           <div style={{ flex: 1, textAlign: 'center', fontWeight: 'bold' }}>
    //             {row.minCO2 !== undefined ? row.minCO2.toFixed(2) : 'N/A'}
    //           </div>
    //           <div style={{ flex: 1, textAlign: 'center', fontWeight: 'bold' }}>
    //             {row.maxCO2 !== undefined ? row.maxCO2.toFixed(2) : 'N/A'}
    //           </div>
    //         </div>
    //       ),
    //       sortFunction: (a, b) => (a.avgCO2 || 0) - (b.avgCO2 || 0)
    //     }
    //   ];

    // const columns = [
    //     {
    //       name: "Date",
    //       selector: "readingDate",
    //       sortable: false,
    //       width: '120px',
    //       headerStyle: {
    //         textAlign: 'center',
    //       },
    //       cell: row => (
    //         <a
    //           href="#!"
    //           onClick={(e) => {
    //             e.preventDefault();
    //             handleDateChange(moment(row.readingDate).tz('Asia/Kolkata').startOf('day').toDate(), row.uuid);
    //           }}
    //         >
    //           {moment(row.readingDate).format('YYYY-MM-DD')}
    //         </a>
    //       ),
    //       sortFunction: (a, b) => new Date(a.createdDate) - new Date(b.createdDate)
    //     },
    //     {
    //       name: (
    //         <div style={{ textAlign: 'center', width: '190px' }}>
    //           <div>Temperature</div>
    //           <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '5px' }}>
    //             <span style={{ fontSize: 'smaller', color: '#36454F' }}>Avg</span>
    //             <span style={{ fontSize: 'smaller', color: '#36454F' }}>Min</span>
    //             <span style={{ fontSize: 'smaller', color: '#36454F' }}>Max</span>
    //           </div>
    //         </div>
    //       ),
    //       selector: "avgTemperature",
    //       width: '250px',
    //       headerStyle: {
    //         textAlign: 'center',
    //       },
    //       sortable: true,
    //       cell: row => (
    //         <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
    //           <div style={{ flex: 1, textAlign: 'left', fontWeight: 'bold', paddingLeft: '5px' }}>
    //             {row.avgTemperature !== undefined ? row.avgTemperature.toFixed(2) : 'N/A'}
    //           </div>
    //           <div style={{ flex: 1, textAlign: 'center', fontWeight: 'bold' }}>
    //             {row.minTemperature !== undefined ? row.minTemperature.toFixed(2) : 'N/A'}
    //           </div>
    //           <div style={{ flex: 1, textAlign: 'right', fontWeight: 'bold', paddingRight: '5px' }}>
    //             {row.maxTemperature !== undefined ? row.maxTemperature.toFixed(2) : 'N/A'}
    //           </div>
    //         </div>
    //       ),
    //       sortFunction: (a, b) => a.avgTemperature - b.avgTemperature
    //     },
    //     {
    //       name: (
    //         <div style={{ textAlign: 'center', width: '190px' }}>
    //           <div>Ammonia</div>
    //           <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '5px' }}>
    //             <span style={{ fontSize: 'smaller', color: '#36454F' }}>Avg</span>
    //             <span style={{ fontSize: 'smaller', color: '#36454F' }}>Min</span>
    //             <span style={{ fontSize: 'smaller', color: '#36454F' }}>Max</span>
    //           </div>
    //         </div>
    //       ),
    //       selector: "avgAmmonia",
    //       width: '250px',
    //       headerStyle: {
    //         textAlign: 'center',
    //       },
    //       sortable: true,
    //       cell: row => (
    //         <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
    //           <div style={{ flex: 1, textAlign: 'left', fontWeight: 'bold', paddingLeft: '5px' }}>
    //             {row.avgAmmonia !== undefined ? row.avgAmmonia.toFixed(3) : 'N/A'}
    //           </div>
    //           <div style={{ flex: 1, textAlign: 'center', fontWeight: 'bold' }}>
    //             {row.minAmmonia !== undefined ? row.minAmmonia.toFixed(3) : 'N/A'}
    //           </div>
    //           <div style={{ flex: 1, textAlign: 'right', fontWeight: 'bold', paddingRight: '5px' }}>
    //             {row.maxAmmonia !== undefined ? row.maxAmmonia.toFixed(3) : 'N/A'}
    //           </div>
    //         </div>
    //       ),
    //       sortFunction: (a, b) => (a.avgAmmonia || 0) - (b.avgAmmonia || 0)
    //     },
    //     {
    //       name: (
    //         <div style={{ textAlign: 'center', width: '190px' }}>
    //           <div>Relative Humidity</div>
    //           <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '5px' }}>
    //             <span style={{ fontSize: 'smaller', color: '#36454F' }}>Avg</span>
    //             <span style={{ fontSize: 'smaller', color: '#36454F' }}>Min</span>
    //             <span style={{ fontSize: 'smaller', color: '#36454F' }}>Max</span>
    //           </div>
    //         </div>
    //       ),
    //       selector: "avgRelativeHumidity",
    //       width: '250px',
    //       headerStyle: {
    //         textAlign: 'center',
    //       },
    //       sortable: true,
    //       cell: row => (
    //         <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
    //           <div style={{ flex: 1, textAlign: 'left', fontWeight: 'bold', paddingLeft: '5px' }}>
    //             {row.avgRelativeHumidity !== undefined ? row.avgRelativeHumidity.toFixed(2) : 'N/A'}
    //           </div>
    //           <div style={{ flex: 1, textAlign: 'center', fontWeight: 'bold' }}>
    //             {row.minRelativeHumidity !== undefined ? row.minRelativeHumidity.toFixed(2) : 'N/A'}
    //           </div>
    //           <div style={{ flex: 1, textAlign: 'right', fontWeight: 'bold', paddingRight: '5px' }}>
    //             {row.maxRelativeHumidity !== undefined ? row.maxRelativeHumidity.toFixed(2) : 'N/A'}
    //           </div>
    //         </div>
    //       ),
    //       sortFunction: (a, b) => (a.avgRelativeHumidity || 0) - (b.avgRelativeHumidity || 0)
    //     },
    //     {
    //       name: (
    //         <div style={{ textAlign: 'center', width: '190px' }}>
    //           <div>Light</div>
    //           <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '5px' }}>
    //             <span style={{ fontSize: 'smaller', color: '#36454F' }}>Avg</span>
    //             <span style={{ fontSize: 'smaller', color: '#36454F' }}>Min</span>
    //             <span style={{ fontSize: 'smaller', color: '#36454F' }}>Max</span>
    //           </div>
    //         </div>
    //       ),
    //       selector: "avgLight",
    //       width: '250px',
    //       headerStyle: {
    //         textAlign: 'center',
    //       },
    //       sortable: true,
    //       cell: row => (
    //         <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
    //           <div style={{ flex: 1, textAlign: 'left', fontWeight: 'bold', paddingLeft: '5px' }}>
    //             {row.avgLight !== undefined ? row.avgLight.toFixed(2) : 'N/A'}
    //           </div>
    //           <div style={{ flex: 1, textAlign: 'center', fontWeight: 'bold' }}>
    //             {row.minLight !== undefined ? row.minLight.toFixed(2) : 'N/A'}
    //           </div>
    //           <div style={{ flex: 1, textAlign: 'right', fontWeight: 'bold', paddingRight: '5px' }}>
    //             {row.maxLight !== undefined ? row.maxLight.toFixed(2) : 'N/A'}
    //           </div>
    //         </div>
    //       ),
    //       sortFunction: (a, b) => (a.avgLight || 0) - (b.avgLight || 0)
    //     },
    //     {
    //       name: (
    //         <div style={{ textAlign: 'center', width: '190px' }}>
    //           <div>Heat Index</div>
    //           <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '5px' }}>
    //             <span style={{ fontSize: 'smaller', color: '#36454F' }}>Avg</span>
    //             <span style={{ fontSize: 'smaller', color: '#36454F' }}>Min</span>
    //             <span style={{ fontSize: 'smaller', color: '#36454F' }}>Max</span>
    //           </div>
    //         </div>
    //       ),
    //       selector: "avgHeatIndex",
    //       width: '250px',
    //       headerStyle: {
    //         textAlign: 'center',
    //       },
    //       sortable: true,
    //       cell: row => (
    //         <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
    //           <div style={{ flex: 1, textAlign: 'left', fontWeight: 'bold', paddingLeft: '5px' }}>
    //             {row.avgHeatIndex !== undefined ? row.avgHeatIndex.toFixed(2) : 'N/A'}
    //           </div>
    //           <div style={{ flex: 1, textAlign: 'center', fontWeight: 'bold' }}>
    //             {row.minHeatIndex !== undefined ? row.minHeatIndex.toFixed(2) : 'N/A'}
    //           </div>
    //           <div style={{ flex: 1, textAlign: 'right', fontWeight: 'bold', paddingRight: '5px' }}>
    //             {row.maxHeatIndex !== undefined ? row.maxHeatIndex.toFixed(2) : 'N/A'}
    //           </div>
    //         </div>
    //       ),
    //       sortFunction: (a, b) => (a.avgHeatIndex || 0) - (b.avgHeatIndex || 0)
    //     },
    //     {
    //       name: (
    //         <div style={{ textAlign: 'center', width: '190px' }}>
    //           <div>CO2</div>
    //           <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '5px' }}>
    //             <span style={{ fontSize: 'smaller', color: '#36454F' }}>Avg</span>
    //             <span style={{ fontSize: 'smaller', color: '#36454F' }}>Min</span>
    //             <span style={{ fontSize: 'smaller', color: '#36454F' }}>Max</span>
    //           </div>
    //         </div>
    //       ),
    //       selector: "avgCO2",
    //       width: '250px',
    //       headerStyle: {
    //         textAlign: 'center',
    //       },
    //       sortable: true,
    //       cell: row => (
    //         <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
    //           <div style={{ flex: 1, textAlign: 'left', fontWeight: 'bold', paddingLeft: '5px' }}>
    //             {row.avgCO2 !== undefined ? row.avgCO2.toFixed(2) : 'N/A'}
    //           </div>
    //           <div style={{ flex: 1, textAlign: 'center', fontWeight: 'bold' }}>
    //             {row.minCO2 !== undefined ? row.minCO2.toFixed(2) : 'N/A'}
    //           </div>
    //           <div style={{ flex: 1, textAlign: 'right', fontWeight: 'bold', paddingRight: '5px' }}>
    //             {row.maxCO2 !== undefined ? row.maxCO2.toFixed(2) : 'N/A'}
    //           </div>
    //         </div>
    //       ),
    //       sortFunction: (a, b) => (a.avgCO2 || 0) - (b.avgCO2 || 0)
    //     }
    //   ];      

    const columns = [
        {
            name: "Date",
            selector: "readingDate",
            sortable: false,
            width: '120px',
            headerStyle: {
                textAlign: 'center',
            },
            cell: row => (
                <a
                    href="#!"
                    onClick={(e) => {
                        e.preventDefault();
                        handleDateChange(moment(row.readingDate).tz('Asia/Kolkata').startOf('day').toDate(), row.uuid);
                    }}
                >
                    {moment(row.readingDate).format('YYYY-MM-DD')}
                </a>
            ),
            sortFunction: (a, b) => new Date(a.readingDate) - new Date(b.readingDate)
        },
        {
            name: (
                <div style={{ textAlign: 'center', width: '190px' }}>
                    <div>Temperature</div>
                    <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '5px' }}>
                        <span style={{ fontSize: 'smaller', color: '#36454F' }}>Avg</span>
                        <span style={{ fontSize: 'smaller', color: '#36454F' }}>Min</span>
                        <span style={{ fontSize: 'smaller', color: '#36454F' }}>Max</span>
                    </div>
                </div>
            ),
            selector: "avgTemperature",
            width: '250px',
            headerStyle: {
                textAlign: 'center',
            },
            sortable: true,
            cell: row => (
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                    <div style={{ flex: 1, textAlign: 'left', fontWeight: 'bold', paddingLeft: '5px', fontFamily: 'monospace' }}>
                    {formatValue(row.avgTemperature)}
                    </div>
                    <div style={{ flex: 1, textAlign: 'center', fontWeight: 'bold', fontFamily: 'monospace' }}>
                    {formatValue(row.minTemperature)}
                    </div>
                    <div style={{ flex: 1, textAlign: 'right', fontWeight: 'bold', paddingRight: '10px', fontFamily: 'monospace' }}>
                    {formatValue(row.maxTemperature)}
                    </div>
                </div>
            ),
            sortFunction: (a, b) => a.avgTemperature - b.avgTemperature
        },
        {
            name: (
                <div style={{ textAlign: 'center', width: '190px' }}>
                    <div>Ammonia</div>
                    <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '5px' }}>
                        <span style={{ fontSize: 'smaller', color: '#36454F' }}>Avg</span>
                        <span style={{ fontSize: 'smaller', color: '#36454F' }}>Min</span>
                        <span style={{ fontSize: 'smaller', color: '#36454F' }}>Max</span>
                    </div>
                </div>
            ),
            selector: "avgAmmonia",
            width: '250px',
            headerStyle: {
                textAlign: 'center',
            },
            sortable: true,
            cell: row => (
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                    <div style={{ flex: 1, textAlign: 'left', fontWeight: 'bold', paddingLeft: '5px', fontFamily: 'monospace' }}>
                        {formatAmmoniaValue(row.avgAmmonia)}
                    </div>
                    <div style={{ flex: 1, textAlign: 'center', fontWeight: 'bold', fontFamily: 'monospace' }}>
                        {formatAmmoniaValue(row.minAmmonia)}
                    </div>
                    <div style={{ flex: 1, textAlign: 'right', fontWeight: 'bold', paddingRight: '10px', fontFamily: 'monospace' }}>
                        {formatAmmoniaValue(row.maxAmmonia )}
                    </div>
                </div>
            ),
            sortFunction: (a, b) => (a.avgAmmonia || 0) - (b.avgAmmonia || 0)
        },
        {
            name: (
                <div style={{ textAlign: 'center', width: '190px' }}>
                    <div>Relative Humidity</div>
                    <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '5px' }}>
                        <span style={{ fontSize: 'smaller', color: '#36454F' }}>Avg</span>
                        <span style={{ fontSize: 'smaller', color: '#36454F' }}>Min</span>
                        <span style={{ fontSize: 'smaller', color: '#36454F' }}>Max</span>
                    </div>
                </div>
            ),
            selector: "avgRelativeHumidity",
            width: '250px',
            headerStyle: {
                textAlign: 'center',
            },
            sortable: true,
            cell: row => (
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                    <div style={{ flex: 1, textAlign: 'left', fontWeight: 'bold', paddingLeft: '5px', fontFamily: 'monospace' }}>
                        {formatValue(row.avgRelativeHumidity)}
                    </div>
                    <div style={{ flex: 1, textAlign: 'center', fontWeight: 'bold', fontFamily: 'monospace' }}>
                        {formatValue(row.minRelativeHumidity)}
                    </div>
                    <div style={{ flex: 1, textAlign: 'right', fontWeight: 'bold', paddingRight: '10px', fontFamily: 'monospace' }}>
                        {formatValue(row.maxRelativeHumidity )}
                    </div>
                </div>
            ),
            sortFunction: (a, b) => (a.avgRelativeHumidity || 0) - (b.avgRelativeHumidity || 0)
        },
        {
            name: (
                <div style={{ textAlign: 'center', width: '190px' }}>
                    <div>Light</div>
                    <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '5px' }}>
                        <span style={{ fontSize: 'smaller', color: '#36454F' }}>Avg</span>
                        <span style={{ fontSize: 'smaller', color: '#36454F' }}>Min</span>
                        <span style={{ fontSize: 'smaller', color: '#36454F' }}>Max</span>
                    </div>
                </div>
            ),
            selector: "avgLight",
            width: '250px',
            headerStyle: {
                textAlign: 'center',
            },
            sortable: true,
            cell: row => (
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                    <div style={{ flex: 1, textAlign: 'left', fontWeight: 'bold', paddingLeft: '5px', fontFamily: 'monospace' }}>
                        {formatValue(row.avgLight)}
                    </div>
                    <div style={{ flex: 1, textAlign: 'center', fontWeight: 'bold', fontFamily: 'monospace' }}>
                        {formatValue(row.minLight )}
                    </div>
                    <div style={{ flex: 1, textAlign: 'right', fontWeight: 'bold', paddingRight: '10px', fontFamily: 'monospace' }}>
                        {formatValue(row.maxLight )}
                    </div>
                </div>
            ),
            sortFunction: (a, b) => (a.avgLight || 0) - (b.avgLight || 0)
        },
        {
            name: (
                <div style={{ textAlign: 'center', width: '190px' }}>
                    <div>Heat Index</div>
                    <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '5px' }}>
                        <span style={{ fontSize: 'smaller', color: '#36454F' }}>Avg</span>
                        <span style={{ fontSize: 'smaller', color: '#36454F' }}>Min</span>
                        <span style={{ fontSize: 'smaller', color: '#36454F' }}>Max</span>
                    </div>
                </div>
            ),
            selector: "avgHeatIndex",
            width: '250px',
            headerStyle: {
                textAlign: 'center',
            },
            sortable: true,
            cell: row => (
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                    <div style={{ flex: 1, textAlign: 'left', fontWeight: 'bold', paddingLeft: '5px', fontFamily: 'monospace' }}>
                        {formatValue(row.avgHeatIndex)}
                    </div>
                    <div style={{ flex: 1, textAlign: 'center', fontWeight: 'bold', fontFamily: 'monospace' }}>
                        {formatValue(row.minHeatIndex)}
                    </div>
                    <div style={{ flex: 1, textAlign: 'right', fontWeight: 'bold', paddingRight: '10px', fontFamily: 'monospace' }}>
                        {formatValue(row.maxHeatIndex)}
                    </div>
                </div>
            ),
            sortFunction: (a, b) => (a.avgHeatIndex || 0) - (b.avgHeatIndex || 0)
        },
        {
            name: (
                <div style={{ textAlign: 'center', width: '190px' }}>
                    <div>CO2</div>
                    <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '5px' }}>
                        <span style={{ fontSize: 'smaller', color: '#36454F' }}>Avg</span>
                        <span style={{ fontSize: 'smaller', color: '#36454F' }}>Min</span>
                        <span style={{ fontSize: 'smaller', color: '#36454F' }}>Max</span>
                    </div>
                </div>
            ),
            selector: "avgCO2",
            width: '250px',
            headerStyle: {
                textAlign: 'center',
            },
            sortable: true,
            cell: row => (
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                    <div style={{ flex: 1, textAlign: 'left', fontWeight: 'bold', paddingLeft: '5px', fontFamily: 'monospace' }}>
                        {formatValue(row.avgCO2)}
                    </div>
                    <div style={{ flex: 1, textAlign: 'center', fontWeight: 'bold', fontFamily: 'monospace' }}>
                        {formatValue(row.minCO2)}
                    </div>
                    <div style={{ flex: 1, textAlign: 'right', fontWeight: 'bold', paddingRight: '10px', fontFamily: 'monospace' }}>
                        {formatValue(row.maxCO2)}
                    </div>
                </div>
            ),
            sortFunction: (a, b) => (a.avgCO2 || 0) - (b.avgCO2 || 0)
        }
    ];


    const modalColumns = [
        // { name: "Time", selector: "createdDate", sortable: true, width: '150px', cell: row => <div>{moment(row.createdDate).tz('Asia/Kolkata').format('YYYY-MM-DD HH:mm')}</div>  },
        {
            name: "Time",
            selector: "createdDate",
            sortable: true,
            headerStyle: {
                textAlign: 'center'  // Centers the header text
            },
            width: '150px',
            cell: row => {
                // const istDate = moment.utc(row.createdDate).tz('Asia/Kolkata'); // Convert from UTC to IST explicitly
                // return <div>{istDate.format('YYYY-MM-DD HH:mm')}</div>;
                const formattedDate = moment.utc(row.createdDate).format('HH:mm');
                return <div>{formattedDate}</div>;
            }, sortFunction: (a, b) => new Date(a.createdDate) - new Date(b.createdDate)
        },
        { name: "Temperature", selector: "temp", sortable: true, width: '150px', cell: row => <div>{row.temp}</div>, sortFunction: (a, b) => a.temp - b.temp, },
        { name: "Ammonia", selector: "nh", sortable: true, width: '150px', cell: row => <div>{row.nh}</div>, sortFunction: (a, b) => a.nh - b.nh, },
        { name: "Heat Index", selector: "heatIndex", sortable: true, width: '150px', cell: row => <div>{row.heatIndex}</div>, sortFunction: (a, b) => a.heatIndex - b.heatIndex, },
        { name: "Relative Humidity", selector: "rh", sortable: true, width: '150px', cell: row => <div>{row.rh}</div>, sortFunction: (a, b) => a.rh - b.rh, },
        { name: "Light", selector: "light", sortable: true, width: '150px', cell: row => <div>{row.light}</div>, sortFunction: (a, b) => a.light - b.light, },
        { name: "CO2", selector: "co", sortable: true, width: '150px', cell: row => <div>{row.co}</div>, sortFunction: (a, b) => a.co - b.co, },
    ];

    return (
        <div className="BatchOperations">
            <div className="row">
                <div className="col-md-12">
                    <h3>Readings</h3>
                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-3">
                                    <label>Select Barn</label>
                                    <select
                                        className="form-control"
                                        value={selectedBarn}
                                        onChange={(e) => {
                                            setSelectedBarn(e.target.value);
                                            fetchBatches(e.target.value);
                                            setSelectedBatch('');
                                            setEndDate(null)
                                            setStartDate(null);
                                            setReadings([]);
                                        }}
                                    >
                                        <option value="">-Select Barn-</option>
                                        {barns.map((barn) => (
                                            <option key={barn.barnID} value={barn.barnID}>
                                                {barn.barnName}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div className="col-md-3">
                                    <label>Select Batch</label>
                                    <select
                                        className="form-control"
                                        value={selectedBatch}
                                        onChange={(e) => {
                                            setSelectedBatch(e.target.value);
                                            setStartDate(null);
                                            setEndDate(null);
                                            setReadings([]);
                                        }
                                        }
                                    >
                                        <option value="">-Select Batch-</option>
                                        {batches.map((batch) => (
                                            <option key={batch.id} value={batch.id.toString()}>
                                                {batch.batchName}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                {/* <div className="col-md-3">
                                    <label>Select Date Range</label>
                                    <DatePicker
                                        selectsRange
                                        startDate={startDate}
                                        endDate={endDate}
                                        onChange={(update) => setDateRange(update)}
                                        withPortal
                                        className="form-control"
                                    />
                                    
                                </div> */}
                                <div className="col-md-3 ">
                                    <label>Start Date</label>
                                    <DatePicker
                                        selected={startDate}
                                        onChange={(date) => { setStartDate(date); setEndDate(null); setReadings([]); }}
                                        customInput={
                                            <div className="input-group">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Start Date"
                                                    value={startDate ? moment(startDate).format('YYYY-MM-DD') : ''}
                                                    readOnly
                                                />
                                                <span className="input-group-text">
                                                    <FaCalendarAlt />
                                                </span>
                                            </div>
                                        }
                                    />
                                </div>
                                <div className="col-md-3 ">
                                    <label>End Date</label>
                                    <DatePicker
                                        selected={endDate}
                                        onChange={(date) => setEndDate(date)}
                                        maxDate={new Date()}
                                        minDate={startDate}
                                        customInput={
                                            <div className="input-group">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="End Date"
                                                    value={endDate ? moment(endDate).format('YYYY-MM-DD') : ''}
                                                    readOnly
                                                />
                                                <span className="input-group-text">
                                                    <FaCalendarAlt />
                                                </span>
                                            </div>
                                        }
                                    />
                                </div>
                            </div>
                            <div className="DeviceReadings mt-4">
                                <div className="row">
                                    {loading && <Loader />}
                                    <div className="col-md-11" style={{ width: '100%' }}>
                                        <div className="d-flex justify-content-between align-items-center">
                                            <h5>Device Readings (Averages per Day)</h5>
                                            {readings !== null && readings.length > 0 &&
                                                (<CSVLink data={readings} style={{ marginRight: '20px' }}>Export Data to CSV</CSVLink>)
                                            }
                                        </div>
                                        {showReadings ? (
                                            <DataTable
                                                progressPending={loading}
                                                columns={columns}
                                                data={readings}
                                                defaultSortField="createdDate"
                                                defaultSortAsc={false}
                                                pagination
                                                dense={false}
                                                highlightOnHover
                                                striped
                                                // bordered
                                                showGridlines
                                                paginationPerPage={15}
                                                paginationRowsPerPageOptions={[15, 25, 50, 100]}
                                                className="custom-data-table"
                                            />
                                        ) : (
                                            <p>Either all fields are not selected or there are no readings for the selected device.</p>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Modal to show detailed reading */}
            <Modal show={showModal} onHide={handleCloseModal} size="xl">
                <Modal.Header closeButton>
                    <Modal.Title>Reading Details for {selectedReading.length > 0 ? moment.utc(selectedReading[0].createdDate).format('YYYY-MM-DD') : ''}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {loading ? (
                        <div className="full-screen-loader">
                            {/* Display the loader while loading is true */}
                           { <section {...containerProps}  style = {{"margin-top": "0px"}}>
                                    {indicatorEl}
                                </section>
                            }
                        </div>
                    ) : (
                        selectedReading.length > 0 ? (
                            <DataTable
                                columns={modalColumns}
                                data={selectedReading}
                                defaultSortField="createdDate"
                                defaultSortAsc={false}
                                pagination
                                dense={false}
                                highlightOnHover
                                striped
                                paginationPerPage={10}
                                paginationRowsPerPageOptions={[10, 15, 20]}
                            />
                        ) : (
                            <p>No reading details available.</p>
                        )
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <CSVLink data={selectedReading} style={{ marginRight: '20px' }}>Export Data to CSV</CSVLink>
                    <Button variant="secondary" onClick={handleCloseModal}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};
export default ReadingByDate;
